import React, { useState } from "react";
import "../estilos/generaltablas/generaltablas.css";
import dataFuncionario from "../datajson/funcionariosmuni.json"

const Funcionarios=()=>{
   const [contacts]=useState(dataFuncionario);
   return(
      <>
         <h1 className="">Directorio Institucional</h1>
         <div className="convo-container">
            <table>
              <thead> 
                <tr>          
                    <th>Cargo</th>
                    <th>Nombres y Apellidos</th>
                    <th>Correo</th>
                </tr>
              </thead>
              <tbody>
                {contacts.map((contact)=> (
                    <tr key={contact.id}>
                      <td>{contact.cargo}</td>
                      <td>{contact.nombre}</td>
                      <td>{contact.correo}</td>     
                </tr>
                
                ))}
                
              </tbody>
            </table>
         </div>
      </>
        
     
   );
}
export default Funcionarios;