import React from 'react';
import "../estilos/layouts/footer.css";
import portal from "../imagenes/imglayouts/imgfooter/webmail.png"
const Footer=()=>{
   return(
         <>
    
         <div className="containerfooter">
            <h4>Municipalidad Provincial de Quispicanchi Gestión 2023 - 2026</h4>
            <ul className='nopuntos'>
                  <li>Jr. César Vallejo Nro. 109, Plaza de Armas de Urcos, Quispicanchi</li>
                  <li>Cusco - Perú</li>
                  <li>Telf.: 974751193</li>
                  <li>Correo Electronico.: muniquispicanchi2023.2026@gmail.com</li>
            </ul>
        </div>
        <div className="containerfooter">
            <h4><b>Telefonos Quispicanchi</b></h4>
            <ul >
                  <li>Compañía de bomberos: 084307105</li>
                  <li>Centro de salud Urcos: 084307076</li>
                  <li>Essalud: 084307015</li>
                  <li>PNP Quispicanchi: 084243240, 966825647 </li>
                  <li>Seguridad Ciudadana: 948833188</li>
                  
            </ul>
        </div>
        <div className="containerfooter">
            <h4><b>webmail</b></h4>
            <div>
            <a href="https://mail.hostinger.com/" target="_blank" rel="noopener noreferrer">
            <img src={portal} alt="pte" />
            </a>
            <h5><b>Horario de Atencion</b></h5>
            <p>Lunes a Viernes de 08:00 a.m. a 05:00 p.m.</p>
        </div>
        </div>   

         </>
   );
}
export default Footer;