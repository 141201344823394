import React from 'react'
import "../estilos/components/festividad.css";
const Festividades = () => {
  return (
    <div className='festividadContainer'>

    <h2 className="festih2">Festividades</h2>
  
      
        <h4 className='estiloTitu'>SARA RAYMI</h4>
        <p className='estiloText'>Culto al Maíz, fiesta que se realiza en el distrito de Huaro, provincia de Quispicanchi, el dia 28 de marzo.</p>
        

        <h4 className='estiloTituo'>CAPULI RAYMI</h4>
        <p className='comitext'>Culto al capulí (cerezo   andino), fiesta que se realiza en el distrito de Cusipata, provincia de   Quispicanchi, en la fecha del día principal de los carnavales.</p>

        <h4 className='estiloTitu'>CCOCHA RAYMI</h4>
        <p className='comitext'>Culto al Agua, la cual se   escenifica en la laguna del dsitrito de Urcos, el día 30 de agosto de   cada año. Dónde participan en las escenificaciones respectivas niños   jóvenes y adultos.</p>

        <h4 className='estiloTitul'>PACHA MAMA RAYMI</h4>
        <p className='comitext'>Culto a la Madre Tierra,   que se escenifica en la plaza de armas del distrito de Ccatcca,   provincia de Quispicanchi, a mediados del mes de agosto.con la finalidad   de rendir homenaje a la pacha mama (LA TIERRA).</p>
      
        <h4 className='estiloTitul'>T´ANTA RAYMI</h4>
        <p className='estiloText'>Culto al pan, que se realiza   en el distrito de Oropesa, provincia de Quispicanchi La villa recibe al   visitante con una inscripción que dice: "Bienvenidos al marquesado de   Valleumbroso de Oropesa, capital nacional del pan". Y es que, desde que   uno llega, percibe por todas partes un intenso olor a pan. Oropesa se   jacta de producir el mejor pan del Cusco. Cuenta con muchos hornos y   buena parte de la población parece dedicarse a este negocio. Desde aquí   se llega a la ciudad. Si le apetece un buen pan, fresco y sabroso, no   dude en acudir a uno de estos hornos. Se los reconoce porque,   generalmente, tienen una canastita de pan en la entrada. Si lo hace,   encontrará a los productores en pleno trabajo y hasta podrá amasar su   propio pan.</p>
        
        <h4 className='estiloTituo'>K´INTU RAYMI</h4>
        <p className='estiloText'>Evocación mítica de los   apus, basicamente es una reunión de todos los curanderos, brujos de   todos el Perú y del extranjero, que se realiza en el centro poblado de   Huasao, distrito de Oropesa, provincia de Quispicanchi, el 30 de agosto.    donde participan curanderos de la zona sur del país y de la region.</p>
      
    </div>

  )
}

export default Festividades;