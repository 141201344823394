import React from 'react'

import "../estilos/components/mesapartes.css";
import imgPersona from "../imagenes/components/mesapartes/PersonaMensaje.png"
import imgMesa from "../imagenes/components/mesapartes/MesaPartes.png"

const MesaPartes = () => {
  return (
    <div className='mesaContainer'>
       <h1 className="mesah1">Mesa de partes virtual de la Municipalidad Provincial de Quispicanchi</h1>
      <h5 className=''>Mesa de partes virtual es un servicio ofrecido por la Municipalidad Provincial de Quispicanchi, con el fin de ofrecerles una plataforma virtual para realizar sus tramites.</h5>
      <h5 className=''>Recuerde ingresar correctamente los datos solicitados y registrarse con su correo electronico.</h5>
    
    <div className='contenedorMesaDePartes'>
        <div className='contenedorImagenMesa'>
          <img src={imgPersona} alt="pte" width={500} />
        </div>
        <a href="https://forms.gle/E5HKmR3TZBDft7Yb6" target="_blank" rel="noopener noreferrer">
            <img className='borderLogoMesa' src={imgMesa} alt="pte" width={200} />
        </a>
    </div>
  </div>
  )
}

export default MesaPartes;