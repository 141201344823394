import "../estilos/components/inicio.css";


import Carousel from 'react-bootstrap/Carousel';
import img1 from "../imagenes/components/inicio/inicio1.jpg"
import img2 from "../imagenes/components/inicio/inicio2.jpg";
import img3 from "../imagenes/components/inicio/inicio3.jpg";
import alcalde from "../imagenes/components/inicio/alcalde.jpg";
import img8 from "../imagenes/components/inicio/inicio8.jpg";
import img7 from "../imagenes/components/inicio/inicio7.jpg";
import img9 from "../imagenes/components/inicio/inicio9.jpg";
import img10 from "../imagenes/components/inicio/inicio10.jpg";

const Inicio = () =>{
   return(
      <div className="container">
         <div className="item item-1">
          <Carousel>

          <Carousel.Item>
            <img
              className='d-block w-100'
              src={img2} alt='laguna'
            />
            
            <Carousel.Caption>
              <h3>Qoyllur Urmana</h3>
              <p>Urcos</p>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <img
              className='d-block w-100'
              src={img1} alt='cristo'
            />
            
            <Carousel.Caption>
              <h3>Plaza Armas</h3>
              <p>Urcos</p>
            </Carousel.Caption>
          </Carousel.Item>
          {/*<Carousel.Item>
            <img
              className='d-block w-100'
              src={img8} alt='plaza'
            />
            <Carousel.Caption>
              <h3>Plaza Armas</h3>
              <p>Urcos</p>
            </Carousel.Caption>
        </Carousel.Item>*/}
        <Carousel.Item>
          <img
              className='d-block w-100'
              src={img3} alt='laguna'
            />
          <Carousel.Caption>
            <h3>.</h3>
            <p>
              .
            </p>
          </Carousel.Caption>
          </Carousel.Item>
          {<Carousel.Item>
          <img
              className='d-block w-100'
              src={img7} alt='cristo'
            />
          <Carousel.Caption>
            {/*<h3>Cristo Blanco</h3>
            <p>
              Urcos
          </p>*/}
          </Carousel.Caption>
          </Carousel.Item>}

          {<Carousel.Item>
          <img
              className='d-block w-100'
              src={img9} alt='mensaje' 
            />
          <Carousel.Caption>
            {/*<h3></h3>
            <p>
              Urcos
            </p>*/}
          </Carousel.Caption>
          </Carousel.Item>}

        </Carousel>
      
      </div>

        <div className="item item-2">
          <div className='contenedorFacebook'>
                  <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fweb.facebook.com%2Fprofile.php%3Fid%3D100088963969826&tabs=timeline&width=390&height=530&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=false&appId" 
                    width="390" height="530" style={{border:"none",overflow:"hidden"}}
                    scrolling="no" frameBorder="0" allowFullScreen="true" 
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
          </div>
        </div>
        <div className="item item-3">
            <h1>Municipalidad Provincial de Quispicanchi</h1>
            <p>La Municipalidad Provincial de Quispicanchi, se ha proyectado trabajar en el camino del buen desarrollo institucional impulsando el uso de las tecnologías de la información y comunicación para crear un nuevo e innovador modelo de administración pública con el propósito de mejorar el uso de los servicios e información a los Quispicanchinos, aumentar la eficiencia y eficacia de la administración pública, promover la inclusión, fomentar la transparencia, colaboración y participación ciudadana, para de esta manera avanzar hacia una Sociedad de la Información y el Conocimiento.</p>
            <p>Quispicanchi, una de las tierras cusqueñas más ricas en historia y tradición
                Según los historiadores, Quispicanchi es una palabra quechua que significa “lugar brillante”, donde habitaron diversas tribus, como los Pinaguas, los cuales posteriormente fueron sometidos por el imperio del inca Pachacútec.
                Por ello, en el lugar se puede hallar diversos restos propios de la época inca, como el Templo Pikillaqta, el Conjunto Arqueológico de Tipón, el Santuario Inca del Apu Pachatusun y la Waca de los Sapos de Piedra.</p> 
        </div>
        <div className="item item-4">
          <img src={alcalde} width="100%" alt="alcalde"/>
        </div>
        <div className="item item-5">
          <h3>Yamil Efraín Castillo Cusihuallpa</h3>
          <h3>Alcalde</h3>
          <p>Con mucho entusiasmo asumimos esta alta responsabilidad de dirigir los destinos de nuestra provincia, y ratificamos que nuestros compromisos serán cumplidos con la participación directa e indirecta de todos y cada uno de ustedes.</p>
          <p>Sabemos de nuestras necesidades a nivel de la provincia, y de manera especial, a nivel de nuestro distrito de Urcos. Estas necesidades iremos aplacando en un corto, mediano o largo plazo según su naturaleza.</p>
          <p>El equipo que hoy nos encontramos al frente de la presente gestión, tiene la satisfacción de contar con jóvenes profesionales y con amplia experiencia en gestión, y lo más importantes contamos con el apoyo de ustedes: hombres y mujeres; adultos y jóvenes que son el ingrediente fundamental para el desarrollo de la provincia Quispicanchi.</p>
        </div>
        <div className="item item-6">
          <img src={img10}  width="100%" alt="descripción"/>
        </div>

      </div>
   );
}
export default Inicio;