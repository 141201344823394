
import React, { useState } from "react";
import "../../estilos/generaltablas/generaltablas.css";
import datapresupartipativo from "../../datajson/transparenciajson/presupuestoparticipativo.json"
import enlapdf from "../../imagenes/imgbajarpdf/bajarpdf.png"
const PresupuestoParticipativo = () =>{
   const [contacts]=useState(datapresupartipativo);
   return(
      <div className="tableContainer">
         <h1 className="">Presupuesto Participativo</h1>
         <div className="convo-container">
            <table>
              <thead> 
                <tr>
                    <th>N°</th>
                    <th>Detalle</th> 
                    <th>Fecha</th>
                    <th>Archivo PDF</th>
                </tr>
              </thead>
              <tbody>
                {contacts.map((contactjson)=> (
                    <tr key={contactjson.id}>
                      <td>{contactjson.id}</td>
                      <td>{contactjson.detalle}</td>
                      <td>{contactjson.fecha}</td>
                      <td><a href={require(`../../${contactjson.filepdf}`)} rel='noreferrer' target='_blank'><img src={enlapdf} alt="pdf" width={34}/></a></td>
                </tr>
                
                ))}
                
              </tbody>
            </table>
         </div>
      </div>
        
     
   );
}
export default PresupuestoParticipativo;