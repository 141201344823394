import React from 'react'
import "../estilos/components/ubicacion.css";
const Ubicacion = () => {
  return (
    <div className='ubicacionContainer'>

    <h2 className="ubicacionh2">Ubicación </h2>  
        <p className='estiloText'>La provincia de Quispicanchi es una de las trece provincias que conforman el departamento del Cuzco en el Sur del Perú.</p>
        <p className='estiloTex'>Limita por el Norte con la provincia de Paucartambo y el departamento de Madre de Dios, por el Este con el departamento de Puno, por el Sur con la provincia de Canchis y la provincia de Acomayo y por el Oeste con la provincia del Cusco y la provincia de Paruro.</p>
        <p className='estiloTexo'>Limita por el Norte con la provincia de Paucartambo y el departamento de Madre de Dios, por el Este con el departamento de Puno, por el Sur con la provincia de Canchis y la provincia de Acomayo y por el Oeste con la provincia del Cusco y la provincia de Paruro.</p>
    
    </div>

  )
}

export default Ubicacion;  