import React from "react";
import "../estilos/subcomponents/subregidores.css";

function SubRegidores(props){
  
   return(
      <div className="contenedorTexto">
         <img className="contenedorImagen"
         src={require(`../imagenes/components/imgregidores/${props.imagen}`)} 
          alt={props.altImagen}/>

         <h5 className="contenedorTitulo">{props.titulo}</h5>
         <p className='contenedorParrafo' >
            {props.texto}
         </p>
         
      </div>
   );
}
export default SubRegidores;