import React from 'react'
import "../estilos/components/comisiones.css";
const Comisiones = () => {
  return (
    <div className='comisionContainer'>

    <h2 className="comih2">CONFORMACIÓN DE COMISIONES ORDINARIAS DE REGIDORES AÑO FISCAL 2024</h2>
  
      
        <h4 className='estiloTitu'>1. COMISIÓN ORDINARIA DE INFRAESTRUCTURA, DESARROLLO URBANO Y RURAL.</h4>
        <p className='estiloText'>PRESIDENTE: JULIAN QQUESUALLPA YAPURA</p>
        <p className='estiloTex'>SECRETARIO: HELAR REYNALDO ANGELES RAMIREZ </p>
        <p className='estiloTexo'>VOCAL : JOSE YAURI CCANAHUIRE</p>

        <h4 className='estiloTituo'>2. COMISIÓN ORDINARIA DE ADMINISTRACION, PLANIFICACIÓN Y PRESUPUESTO.</h4>
        <p className='comitext'>PRESIDENTE: JOSE YAURI CCANAHUIRE</p>
        <p className='comitext'>SECRETARIO: JULIAN QQUESUALLPA YAPURA</p>
        <p className='comitext'>VOCAL: JULIAN CONDORI SUCLLI</p>

        <h4 className='estiloTitu'>3. COMISIÓN ORDINARIA DE DESARROLLO ECONÓMICO Y PROMOCIÓN EMPRESARIAL.</h4>
        <p className='comitext'>PRESIDENTE: JULIAN CONDORI SUCLLI</p>
        <p className='comitext'>SECRETARIO: RICHARD HUAMAN MAMANI</p>
        <p className='comitext'>VOCAL: MAIDA TUNQUIPA TACURI</p>

        <h4 className='estiloTitul'>4. COMISIÓN ORDINARIA DE DESARROLLO SOCIAL.</h4>
        <p className='comitext'>PRESIDENTE: HELAR REYNALDO ANGELES RAMIREZ</p>
        <p className='comitext'>SECRETARIO: YENNIFER HANCCO PACHA</p>
        <p className='estiloText'>VOCAL: RICHARD HUAMAN HUAMAN</p>

        <h4 className='estiloTitul'>5. COMISIÓN ORDINARIA DE JUVENTUDES Y EQUIDAD DE GÉNERO.</h4>
        <p className='estiloText'>PRESIDENTE: RICHARD HUAMAN HUAMAN</p>
        <p className='estiloText'>SECRETARIO: JOSE YAURI CCANAHUIRE</p>
        <p className='estiloText'>VOCAL: EMILIO DEL CARMEN LORENZO OLIVERA FLOREZ</p>

        <h4 className='estiloTituo'>6. COMISIÓN ORDINARIA DE TRANSPORTES Y VIALIDAD.</h4>
        <p className='estiloText'>PRESIDENTE: EMILIO DEL CARMEN LORENZO OLIVERA FLOREZ </p>
        <p className='estiloText'>SECRETARIO: MAIDA TUNQUIPA TACURI</p>
        <p className='estiloText'>VOCAL: HELAR REYNALDO ANGELES RAMIREZ</p>

        <h4 className='estiloTitul'>7. COMISIÓN ORDINARIA DE SERVICIOS PÚBLICOS Y MERCADO.</h4>
        <p className='estiloText'>PRESIDENTE: LUCIA MILDRID AUCCATINCO MONRROY</p>
        <p className='estiloText'>SECRETARIO: EMILIO DEL CARMEN LORENZO OLIVERA FLOREZ</p>
        <p className='estiloTexo'>VOCAL: JULIAN QQUESUALLPA YAPURA</p>

        <h4 className='estiloTitlo'>8. COMISIÓN ORDINARIA DE ASUNTOS LEGALES Y ÉTICA.</h4>
        <p className='estiloTeto'>PRESIDENTE: YENNIFER HANCCO PACHA</p>
        <p className='estiloTeto'>SECRETARIO: JULIAN CONDORI SUCLLI</p>
        <p className='estiloTeto'>VOCAL: LUCIA MILDRID AUCCATINCO MONRROY</p>

        <h4 className='estiloTtulo'>9. COMISIÓN ORDINARIA DE ECOLOGÍA Y MEDIO AMBIENTE.</h4>
        <p className='estiloTxto'>PRESIDENTE: MAIDA TUNQUIPA TACURI</p>
        <p className='estiloTxto'>SECRETARIO: LUCIA MILDRID AUCCATINCO MONRROY</p>
        <p className='estiloTxto'>VOCAL: YENNIFER HANCCO PACHA</p>
      
    </div>

  )
}

export default Comisiones;