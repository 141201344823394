import "../estilos/components/contacto.css";

const Contacto = () =>{
   return(
      <div className="contactoContainer">
         <h1 className="contactoh1">Contacto</h1>
         <div className="contactocss">
            <ul>
               <li>Dirección: Jr. César Vallejo Nro. 109, Plaza de Armas de Urcos, Quispicanchi.</li>
               <li>   Cusco - Perú </li>
               <li>Número de celular: 974751193 </li>
               <li>Correo electronico: muniquispicanchi2023.2026@gmail.com</li>
            </ul>
         </div>
      </div>
   );
}
export default Contacto;