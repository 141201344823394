import SubRegidores from "../subcomponents/subregidores";

function Regidores() {
<h1>Regidores de la Municipalidad Provincial de Quispicanchi</h1>
  return (
    
    <div className="App">
      <div>
      <h1 className="contactoh1">Regidores de la Municipalidad Provincial de Quispicanchi</h1>
      </div>
      <div className='contenedorCajas'> 
        <SubRegidores
          imagen='MaidaTunquipa.jpg'
          altImagen= 'foto regidor 1'
          titulo= 'Tec. Maida Tunquipa Tacuri'
          texto='1ra Regidora'
        />
        <SubRegidores
          imagen='RichardHuaman.jpg'
          altImagen= 'foto regidor 2'
          titulo= 'Tec. Richard Huaman Mamani'
          texto='2do Regidor'
        />
        <SubRegidores
          imagen='YenniferHancco.jpg'
          altImagen= 'foto regidor 3'
          titulo= 'Abog. Yennifer Hancco Pacha'
          texto='3ra Regidora'
        />
        <SubRegidores
          imagen='Julian-Yapura.jpg'
          altImagen= 'foto regidor 4'
          titulo= 'Sr. Julian Qquesuallpa Yapura'
          texto='4to Regidor'
        />
        <SubRegidores
          imagen='Lucia-Auccatinco.jpg'
          altImagen= 'foto regidor 5'
          titulo= 'Tec. Lucia Milgrid Auccatinco Monroy'
          texto='5ta Regidora'
        />
        <SubRegidores
          imagen='Jose-Yauri.jpg'
          altImagen= 'foto regidor 6'
          titulo= 'Tec. Jose Yauri Ccanahuire'
          texto='6to Regidor'
        />
        <SubRegidores
          imagen='Helar-Angeles.jpg'
          altImagen= 'foto regidor 7'
          titulo= 'Lic. Helar Reynaldo Angeles Ramirez '
          texto='7mo Regidor'
        />
        <SubRegidores
          imagen='Emilio_Olivera.jpg'
          altImagen= 'foto regidor 8'
          titulo= 'C.D. Emilio del Carmen Lorenzo Olivera Flores'
          texto='8vo Regidor'
        />
        <SubRegidores
          imagen='Julian-Condori.jpg'
          altImagen= 'foto regidor 9'
          titulo= 'Sr. Julian Condori Suclli'
          texto='9no Regidor'
        />
      </div>
    </div>
  );
}

export default Regidores;
