import { Link } from "react-router-dom";
import logo2023 from "../imagenes/imglayouts/imgheader/logo2023.png"
import portal from "../imagenes/imglayouts/imgheader/portal2023.png"
import mesa from "../imagenes/imglayouts/imgheader/mesapartes.png"
import allin from "../imagenes/imglayouts/imgheader/allinkausay.ico"
import "../estilos/layouts/header.css";
const Header=()=>{
   return(
     <div className="head">
         <div className="headers">
            <div className="brand">
              <Link    to="/"> <img src={logo2023} alt="" width={330} /></Link>
            </div>
            
           <div>
            <a href="https://facilita.gob.pe/t/6983" target="_blank" rel="noopener noreferrer">
              <img src={mesa} alt="mesa" width={100} />
            </a>
            
            <Link to="#">
              <img src={allin} alt="allin" width={90} />
            </Link>

            <a href="https://www.transparencia.gob.pe/enlaces/pte_transparencia_enlaces.aspx?id_entidad=67965" target="_blank" rel="noopener noreferrer">
             <img src={portal} alt="pte" width={100} />
            </a>
           </div>

       </div>
       {/*<div className="headertext">
        <a href="https://www.muniurcos.gob.pe/static/media/2024_decretoalcaldia_03_19092024.06644ab1013a0030cbaa.pdf" target="_blank" rel="noopener noreferrer">
        <h5 className="headertext">Decreto de Alcaldía N° 03-2024-MPQ/U. "Audiencia pública de rendición de cuentas de la Municipalidad Provincial de Quispicanchi"</h5>
        </a>
        
      </div>
      <div className="headertext2">
        <h6>Fecha: Lunes, 30 de setiembre 2024. Hora: 9:00 a.m.
        Lugar: Plaza de Armas del distrito de Urcos.</h6>
      </div> */}
      
   </div>
   );
}
export default Header;